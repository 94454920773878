body {
  background-image: "icons/logo-icon-256.png";
}
p {
  font-size: 1rem;
}
.xs-hide {
  display: none;
}
@media only screen and (min-width: 48em) {
  .xs-hide {
    display: block;
  }
  .sm-hide {
    display: none;
  }
}
@media only screen and (min-width: 64em) {
  .sm-hide {
    display: block;
  }
  .md-hide {
    display: none;
  }
}
@media only screen and (min-width: 75em) {
  .md-hide {
    display: block;
  }
  .lg-hide {
    display: none;
  }
}
.bg-tertiary {
  background-color: #dbe4ff;
}
.bg-quaternary {
  background-color: rgba(60,90,120,0.4);
}
.bg-quinternary {
  background-color: rgba(10,0,30,0.5);
}
.bg-accent {
  background-color: #d2daeb;
}
.q-page-container {
  transition-duration: 2000;
}
.padding {
  padding: 0 2rem;
}
.q-layout-header.options {
  box-shadow: none;
  border-bottom: 1px solid #4267ae;
  padding: 0 1rem;
}
body.desktop .q-hoverable:hover > .q-focus-helper {
  opacity: 0.4;
}
hr.margin {
  margin: 1rem 0 1rem 0;
}
.center {
  text-align: center;
}
h1 {
  color: #4267ae;
  font-weight: bold;
  font-size: 2.2rem;
  font-family: 'Roboto Condensed', sans-serif;
  margin: 0;
}
h1.page-title {
  color: #d2daeb;
  padding: 0 2rem;
  text-align: center;
}
h1 .q-btn,
h1 .q-btn-group {
  margin-left: 1rem;
  margin-top: 0;
}
h1.adder {
  position: relative;
}
h2 {
  padding: 0;
  margin: 0;
  color: #d2daeb;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 2rem;
  margin-bottom: 1rem;
  line-height: 2rem;
  position: relative;
}
h2:not(:first-of-type) {
  margin-top: 2rem;
}
h2 span {
  font-size: 1rem;
  font-weight: normal;
}
h2 .q-btn.add {
  position: absolute;
  right: 0;
  top: -0.3rem;
  background-color: #4267ae;
}
h2 .q-btn.remove {
  position: absolute;
  right: 0;
  top: 0;
}
h2.adder {
  position: relative;
}
h3 {
  position: relative;
  padding: 0;
  margin: 1rem 0 1rem 0;
  color: #4267ae;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 1.5rem;
  line-height: 1.5rem;
}
h3 .q-btn.add {
  position: absolute;
  right: 0px;
  top: -0.3rem;
  background-color: #4267ae;
  color: #fff;
}
h3 .q-btn.remove {
  background-color: #4267ae;
  color: #fff;
  position: absolute;
  right: 0;
  top: 0;
}
h3.adder {
  position: relative;
}
h4 {
  padding: 0;
  margin: 1rem 0 1rem 0;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 1rem;
  line-height: 1rem;
  color: #886a9e;
  font-weight: bold;
  letter-spacing: 1.4px;
  opacity: 0.7;
}
h4.adder {
  position: relative;
}
.q-spinner.page-title {
  margin-left: 1rem;
}
h5 {
  padding: 0;
  margin: 1rem 0 1rem 0;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 0.8rem;
  letter-spacing: 1.2px;
  line-height: 1rem;
  color: #886a9e;
  opacity: 0.7;
}
h5.adder {
  position: relative;
}
.q-spinner.page-title {
  margin-left: 1rem;
}
.q-btn.add,
.q-btn.remove {
  position: relative;
  font-size: 1.1rem;
  border-radius: 5px;
  cursor: pointer;
  padding: 0.4rem 0 0 0.2rem;
  border: 1px solid #dbe4ff;
  width: 2rem;
  height: 2rem;
}
.q-btn.add:hover,
.q-btn.remove:hover {
  background-color: #886a9e;
  color: #fff;
  width: ;
}
.q-btn.add .q-icon,
.q-btn.remove .q-icon {
  margin-top: -28px;
  margin-left: -8px;
}
.left {
  text-align: left;
}
.right {
  text-align: right;
}
.box.borderless {
  padding: 1rem 2rem;
}
.box.border {
  background: #dbe4ff;
  padding: 1rem;
  margin: 1rem 0 0 0;
}
.box.border .inner {
  padding: 1rem;
  border-radius: 8px;
  border: 1px solid transparent;
}
.box.border .inner.disabled-form {
  background: #dbe4ff;
}
.box.border .inner.disabled-form .q-card {
  background: #dbe4ff;
}
.box.border .inner:not(.disabled-form) {
  background: #fff;
}
.box.border .inner:not(.disabled-form) .q-card {
  background: #fff;
}
.q-btn-group {
  margin: 0.5rem 0 0 0;
  border-radius: 8px;
}
.q-btn-group .q-btn {
  margin: 0;
  border-radius: 8px;
}
.q-btn-group .q-btn:not(:first-of-type) {
  border-left: 1px solid #fff;
}
#signature-pad {
  border-left: 1px solid #808080;
  border-right: 1px solid #808080;
  border-top: 1px solid #808080;
}
.spacer {
  margin-bottom: 2rem;
}
.row.tall {
  margin-top: 0.5rem;
}
.q-alert {
  border-radius: 8px;
}
.q-alert>.q-alert-side .q-icon {
  font-size: 2.5rem;
}
.q-alert>.q-btn .q-icon {
  font-size: 2rem;
  min-height: 2rem;
}
.q-checkbox {
  margin-right: 1rem;
}
.sub {
  position: relative;
}
.sub:first-of-type {
  margin: 0.5rem 0 0 0;
}
.sub .q-icon {
  font-size: 2rem;
  font-weight: 300;
  color: #d3d3d3;
}
.exp.q-time {
  font-size: 2rem;
  width: auto;
  height: auto;
  max-width: 200%;
}
.exp.q-time__header-label {
  font-size: 3rem;
}
.exp.q-time__clock {
  font-size: 2rem;
}
.exp.q-time__clock-position {
  font-size: 2rem;
  width: 2rem;
  height: 2rem;
}
.exp.q-time .q-btn {
  font-size: 2rem;
  border-radius: 8px;
  padding: 0 20px;
}
.exp.q-date {
  font-size: 2rem;
  width: auto;
  height: auto;
}
.exp.q-date__header {
  height: 8rem !important;
}
.exp.q-date__header-subtitle {
  font-size: 2rem;
  height: 3rem;
}
.exp.q-date__header-title-label {
  font-size: 2rem;
}
.exp.q-date__arrow .q-icon {
  font-size: 2rem;
}
.exp.q-date__arrow .q-anchor--skip {
  min-height: 4rem;
}
.exp.q-date__calendar-item button {
  width: 4.3rem;
  height: 4.3rem;
}
.exp.q-date__calendar-weekdays > div {
  font-size: 2rem;
}
.exp.q-date .q-btn {
  font-size: 2rem;
  border-radius: 8px;
  padding: 0 20px;
}
.datetime >.q-field__inner >.q-field__control:after {
  transform: scale3d(0, 1, 1);
}
.datetime >.q-field__inner >.q-field__control:before {
  border: 0;
}
.datetime.q-field--auto-height.q-field--labeled .q-field__control-container {
  padding-top: 0;
}
.datetime .q-field__label {
  padding-bottom: 50px;
}
.q-field--labeled .q-field__native,
.q-field--labeled .q-field__prefix,
.q-field--labeled .q-field__suffix {
  line-height: 24px;
  padding-top: 24px;
  padding-bottom: 0px;
}
.q-field {
  flex: auto;
}
.q-field.thin .q-field__control-container {
  padding-top: 16px;
}
.q-field__control .q-option-group .q-radio__inner + div.q-radio__label {
  color: #000;
}
.q-field__control .q-option-group .q-radio__inner--active + div.q-radio__label {
  color: #4267ae;
}
.q-field.small .q-field-label-inner {
  font-size: 0.75rem;
}
.q-stepper {
  box-shadow: none;
  border: none;
  margin: 0;
  padding: 0;
}
.q-stepper__tab--done {
  color: #21aa45;
}
.q-stepper .q-stepper-nav,
.q-stepper .q-stepper-tab {
  flex: 1;
}
.q-stepper .q-stepper-nav.step-active .q-stepper-dot,
.q-stepper .q-stepper-tab.step-active .q-stepper-dot {
  background: #4267ae;
}
.q-stepper .q-stepper-nav.step-active .q-stepper-dot span,
.q-stepper .q-stepper-tab.step-active .q-stepper-dot span {
  color: #fff;
}
.q-stepper .q-stepper-nav:not(.step-active) .q-stepper-dot,
.q-stepper .q-stepper-tab:not(.step-active) .q-stepper-dot {
  color: #4267ae;
  background: #fff;
}
.q-stepper .q-stepper-dot {
  border: 2px solid #4267ae;
}
.q-stepper .q-stepper-dot span {
  color: #4267ae;
}
.q-stepper-horizontal > .q-stepper-step > .q-stepper-step-content > .q-stepper-step-inner {
  padding: 0;
}
.q-stepper-horizontal > .q-stepper-header .q-stepper-line:before,
.q-stepper-horizontal > .q-stepper-header .q-stepper-line:after {
  position: absolute;
  top: 50%;
  height: 2px;
  width: 100vw;
  background: #4267ae;
}
.q-select.sub {
  margin-left: 0px;
}
.q-select.accountcodes-ellipses .q-field__inner .q-field__control-container .q-field__native > span {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.q-if-label-spacer {
  white-space: normal;
}
.caption {
  color: #9e9e9e;
}
.q-card {
  padding: 1rem;
  margin: 0 0 1rem 0;
  position: relative;
  border: 1px solid #ddd;
  box-shadow: 0 2px 10px 3px rgba(0,0,0,0.2), 0 2px 2px rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12);
}
.q-card.country {
  padding: 1rem;
  margin-bottom: 1rem;
}
.q-card >button.q-btn.add,
.q-card >button.q-btn.remove {
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
}
textarea.wide {
  width: 100%;
  min-height: 5rem;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border: 1px solid #ccc;
  box-sizing: border-box;
}
.body--dark .box.border {
  background: #4267ae;
}
.body--dark .box.border .inner {
  background: #1d1d1d;
}
.body--dark .box.border .inner.disabled-form {
  background: #3e3d3e;
}
.body--dark .box.border .inner.disabled-form .q-card {
  background: #3e3d3e;
}
.body--dark .box.border .inner:not(.disabled-form) {
  background: #1d1d1d;
}
.body--dark .box.border .inner:not(.disabled-form) .q-card {
  background: #1d1d1d;
}
.body--dark .q-field__control .q-option-group .q-radio__inner + div.q-radio__label {
  color: #fff;
}
.body--dark .q-list--dark .q-item.current {
  background-color: #fff;
}
.body--dark .q-list--dark .q-item--active .q-item__label--caption {
  color: #000;
}
strong.secondary {
  color: #886a9e;
}
.person-input >.q-field--standard >.q-field__inner >.q-field__control:after {
  transform: scale3d(0, 1, 1);
}
.person-input >.q-field--standard >.q-field__inner >.q-field__control:before {
  border: 0;
}
.person-input >.q-field--standard >.q-field__inner >.q-field__control >.q-field__control-container >.col-xs-12 >.q-field--standard >.q-field__inner >.q-field__control:after {
  transform: scale3d(0, 1, 1);
}
.person-input >.q-field--standard >.q-field__inner >.q-field__control >.q-field__control-container >.col-xs-12 >.q-field--standard >.q-field__inner >.q-field__control:before {
  border: 0;
}
label {
  font-size: 0.75rem;
  display: block;
}
/*# sourceMappingURL=src/css/app.css.map */